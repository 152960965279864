<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">TRATAMIENTO DE DATOS PERSONALES</span>
    </v-card-title>
    <v-card-text>
      <div class="text-justify mt-3">
        <p>De conformidad con la política de protección de datos personales de QNT y las Leyes 1581 de 2012 y 1266 de 2008, le informamos que al proporcionar sus datos personales a QNT, usted está autorizando expresamente a que los mismos sean recolectados, almacenados, utilizados, procesados, administrados, analizados, circulados, transmitidos y/o transferidos y en general sometidos a cualquier tipo de tratamiento legalmente permitido por parte de QNT, en calidad de Responsable de la información, y de las entidades públicas o privadas con quienes QNT tenga una relación civil, comercial y/o contractual vigente, para que las mismas actúen en calidad de responsables y/o encargadas de su información, conforme a los usos autorizados y a la normatividad vigente.</p>
        <p>El tratamiento antes señalado se realizará sobre datos personales, que incluyen, pero no se limitan a su: nombre, número de identificación, número de contacto, dirección de domicilio y/o trabajo, correo electrónico, información financiera, crediticia, entre otra. Dicha información, será tratada conforme a las finalidades señaladas en la política de protección de datos de QNT y en general con el objetivo dar cumplimiento a las actividades previstas en su objeto social; cumplir con obligaciones legales y contractuales contraídas con ocasión a la prestación de servicios de rebancarización, administración de cartera ofrecido al público y otros y la ley; tramitar solicitudes de crédito y otorgar información sobre los servicios prestados; efectuar estudios de crédito por si mismo o a través de terceros ; actualizar bases de datos de clientes, proveedores, aliados comerciales y etc.; transmitir y/o transferir a las entidades de crédito información sobre sus clientes a fin de que las mismas puedan efectuar contacto directo con dichas personas, para el monitoreo y/o alertamiento de fraudes transaccional y en los casos que fuera necesario generar el bloqueo preventivo del o los productos financieros del cliente; generar el envío de publicidad sobre productos nuevos y/o existentes, adelantar campañas de marketing, así como elaborar estudios, estadísticas, encuestas, análisis de tendencias, relacionadas con los servicios que presta QNT, reportar a los Operadores de información, los datos personales de naturaleza financiera, crediticia, comercial del cliente; consultar, en cualquier tiempo, en cualquier base de datos manejada por un operador de información financiera y crediticia, todo tipo información relevante para conocer su desempeño como deudor, su capacidad de pago, la viabilidad para entablar o mantener una relación contractual, o para cualquier otra finalidad se encuentre permitida por la Ley.</p>
        <p>Los mencionados datos personales serán recolectados por QNT a partir de la información entregada por el Titular a través de canales como Página Web, Aplicación móvil de QNT, y otras fuentes legítimas. Usted podrá solicitar en cualquier momento información sobre sus datos personales, actualizarlos, rectificarlos, revocarlos, suprimirlos, presentar reclamaciones y en general, ejercer todos los derechos que le corresponden como Titular de los Datos Personales en virtud de lo dispuesto en l Ley 1581 de 2012, 1266 de 2008, dirigiendo un mensaje a la dirección de correo electrónico servicioalcliente@qnt.com.co.</p>
        <p>La aceptación de la presente autorización para el tratamiento de su información, implica su conocimiento previo de la Política de Tratamiento de Datos Personales de QNT, la cual se encuentra publicada en la página web
          <a
            href="https://qnt.com.co/tratamiento-de-datos-personales/"
            target="_blank"
          >https://qnt.com.co/tratamiento-de-datos-personales/</a> y la aplicación MiQnt, por lo que a fin de que usted pueda acceder, mantener y en general establecer un vínculo comercial con la compañía, es su deber consultarla y conocerla, presentado en los casos en que no se encuentre de acuerdo con los términos señalados en la política o la presente autorización, sus inquietudes e inconformidades al correo dispuesto anteriormente, en un plazo no mayor a tres (3) días hábiles después de habérsele dado a conocer el presente documento, vencido el término señalado se entenderá aceptado su contenido, salvo los casos en que la firma del Titular fuera expresamente necesaria para demostrar su consentimiento.
        </p>
        <p>El Titular declara que la información suministrada a QNT en todo momento, es verídica y que otorga su consentimiento libre, voluntario y expreso a QNT de efectuar el tratamiento de sus datos dado a conocer antes de su recolección. , Así también, autoriza a QNT para que, en el evento en el que cualquier solicitud de producto sea negada, elimine, por los medios que QNT establezca, todos los documentos que hubiera aportado para el estudio y análisis realizado por QNT.</p>
      </div>

      <div>
        <p class="mt-5 pt-5">
          <u>{{identification_number}}</u>
          <br />
          No. Documento
        </p>
        <p>Fecha: {{dateFormatted}}</p>
      </div>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        block
        color="secondary"
        @click.stop.prevent="$emit('accept')"
      >
        Acepto términos legales
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>

export default {
  name: "DocumentPersonalData",
  props: {
    identification_number: {
      required: true,
      type: String
    },
    date: {
      default: () => (new Date()),
      type: Date
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
  },
  computed: {
    dateFormatted () {
      return this.formatDate(this.date.toLocaleString().substr(0, 10))
    }
  }

}
</script>
