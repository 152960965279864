<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">ACEPTACION MANEJO FIRMAS ELECTRONICAS</span>
    </v-card-title>
    <v-card-text>
      <div class="text-justify mt-3">
        <p>
          Convengo con QNT S.A.S que, a partir de la presente fecha, si realizo operaciones referentes a los contratos, productos o servicios con los que me encuentro vinculado o autorizo o genero cualquier comunicación, documento, orden u otra actuación con el “botón o contraseña o firma electrónica o digital”. Cada documento que genere, operación que realice, firme, valide, ordene o autorice en la forma antes indicada, será vinculante y sustituye o reemplaza para todos los efectos mi firma física y reconozco que tales actuaciones, documentos, ordenes u operaciones, tienen todos los efectos jurídicos vinculantes.
        </p>
        <p>Se conviene igualmente que mi firma electrónica o digital es personal, confidencial e intransferible por el cual me obligo a mantener el control y la custodia de ella y de los actos de creación de la firma, actuar con la máxima diligencia para evitar la utilización no autorizada de esta y de mis datos de creación, asumiendo las consecuencias de cualquier falla. Igualmente, daré aviso oportuno al QNT S.A.S de cualquier situación anormal que se presente.</p>
        <p>
          De igual forma QNT S.A.S podrá identificarme mediante preguntas de seguridad, un código enviado mediante un mensaje a mi teléfono móvil registrado en QNT S.A.S, un código enviado a mi correo electrónico registrado QNT S.A.S, a través de mi ubicación, de mi dirección IP, de mi huella digital, o la foto de ella, los datos de mi ordenador, los datos de mi teléfono móvil, o mediante un cálculo sobre cualquiera de mis datos, mi fotografía, un clic en una casilla o la mezcla de dos o más de ellas.
        </p>
        <p>
          Me obligo a utilizar la firma o contraseña convenida únicamente para los usos y conforme a los procedimientos de QNT S.A.S y a solicitar la revocación de la firma frente a cualquier situación que se presente y que pueda afectar la seguridad o las operaciones; todo lo aquí convenido estará vigente y producirá los efectos correspondientes frente a todas las ordenes, operaciones o documentos que firme u ordene según el caso, mientras tenga la calidad de cliente de QNT S.A.S Todo lo anterior en los términos del art. 824 del C. de Co.
        </p>
      </div>
      <div>
        <p class="mt-5 pt-5">
          <u>{{identification_number}}</u>
          <br />
          No. Documento
        </p>
        <p>Fecha: {{dateFormatted}}</p>
      </div>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        block
        color="secondary"
        @click.stop.prevent="$emit('accept')"
      >
        Acepto términos legales
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>

export default {
  name: "DocumentDigitalSign",
  props: {
    identification_number: {
      required: true,
      type: String
    },
    date: {
      default: () => (new Date()),
      type: Date
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
  },
  computed: {
    dateFormatted () {
      return this.formatDate(this.date.toLocaleString().substr(0, 10))
    }
  }

}
</script>
