<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">CONTRATO TARJETA DE CRÉDITO</span>
    </v-card-title>
    <v-card-text>
      <div class="text-justify mt-3">
        <p>
          Al brindar estos datos, usted ha solicitado ser Beneficiario de la
          Tarjeta de Crédito Corporativa de QNT S.A.S. ("<b>QNT</b>") expedida
          por el Banco Finandina S.A. (el "<b>Banco</b>"). Con esta solicitud usted
          autoriza a QNT a proporcionar al Banco toda la información
          necesaria para que éste adelante las verificaciones a que haya
          lugar, y para que se adelante un estudio de crédito que podrá
          resultar en el otorgamiento de un cupo de crédito reflejado en
          una tarjeta de crédito.</p>
        <p>
          Usted acepta y comprende que QNT no es una entidad
          financiera, y que, por lo tanto, no se encuentra sometida a
          vigilancia de la Superintendencia Financiera de Colombia. La
          tarjeta de crédito cuya emisión podrá resultar del estudio de
          crédito correspondiente, corresponde a una tarjeta emitida por
          el Banco que permite al beneficiario o tarjetahabiente girar
          contra un cupo de crédito que el Banco ha otorgado a QNT. Así,
          desde el momento que usted recibe y hace uso de la Tarjeta de
          Crédito, asume frente a QNT las mismas obligaciones que QNT
          ha asumido con el Banco, estas le serán informadas por los
          canales disponibles de QNT.</p>
        <p>
          Se entenderá que Usted está facultado para hacer uso de la
          Tarjeta de Crédito desde el momento de su habilitación en los
          sistemas del Banco y hasta que QNT le comunique al Banco por
          escrito, su deseo de excluirlo o sustituirlo, o cuando termine el
          contrato de crédito por cualquiera de las causales estipuladas en
          el mismo o en la Ley.
        </p>
        <p>
          Usted acepta y autoriza que QNT cargue mensualmente a su
          Tarjeta de crédito el valor correspondiente a la cuota de
          administración la cual podrá incrementar cada año previa
          comunicación al cliente.
        </p>
        <p>
          En consecuencia, Usted será responsable del pago de la totalidad de los importes indicados en el estado de cuenta de la Tarjeta de Crédito, sin que pueda oponer ninguna excepción o defensa basada en su situación personal, ni en el mal uso de la Tarjeta de Crédito, de acuerdo a los términos y condiciones de este contrato, ni la falta de capacidad de pago. En el evento que QNT, en su calidad de avalista, deba asumir alguna de las obligaciones derivadas de la Tarjeta de Crédito, en favor de Banco, Usted reconoce y acepta que QNT pueda repetir en su contra, llenando los instrumentos de garantía, pagarés y utilizando las demás herramientas legales
          para perseguir ejecutivamente el pago de las obligaciones insolutas.
        </p>
        <p>
          QNT podrá limitar y/o aumentar el monto del uso del cupo del crédito, y establecer límite al uso de la Tarjeta de Crédito, de conformidad con sus políticas internas, bajo las condiciones estipuladas en el presente contrato y en la Ley.
        </p>
        <p>
          QNT y el Banco podrán negarse a la eventual solicitud de aumento de cupo de crédito, así como revocar o cancelar el mismo, en cuyo caso se le informará debidamente las razones de tal negativa o decisión. El monto que en definitiva constituya el límite o Cupo de crédito aprobado le será indicado debida y oportunamente por QNT. Usted se obliga a no exceder el límite o Cupo de crédito aprobado otorgado por QNT y, en caso de quese autorice cualquier sobregiro, quedará obligado a pagar el monto íntegro del mismo, incluyendo los interesesconvencionales, intereses moratorios y comisiones quecorrespondan, de ser el caso.
        </p>
        <p>
          En caso de que el cliente incurra en mora igual o superior a 5 díasa partir de la fecha límite de pago, el cliente asumirá los gastosderivados del despliegue de gestión de cobranza que realice QNThasta el día de la normalización de la obligación. Dichos gastosde cobranza podrán variar dependiendo la etapa y alturamoratoria de la obligación.
        </p>
        <p>
          <b>Autorización de entrega de documentos y aceptación de tarjeta de crédito</b>: Manifiesto y acepto las siguientes condiciones especiales convenidas con QNT: 1) Que he diligenciado y entregado a QNT la solicitud de tarjeta de crédito para el proceso de estudio de crédito, así mismo he aceptado y firmado un pagare y su carta de instrucciones. 2) En caso de que por razones propias de QNT, la tarjeta de crédito que me haya sido entregada no pueda ser activada, acepto ser responsable de la destrucción del plástico, así como de las consecuencias que se puedan derivar por la omisión de esta obligación. 3) Autorizo a QNT de forma permanente e irrevocable, para que comparta con el Banco que emite esta tarjeta de crédito y con el operador de entrega de tarjetas correspondiente, la información de financiera y de contacto necesaria para ejecutar esta vinculación. 4) Declaro que conozco y acepto que la tarjeta de crédito entregada se encuentra inactiva y expresamente autorizo a QNT y/o al Banco para que, de ser el caso, realice la activación automática de la misma. Si el Banco no genera la activación de la tarjeta, podré solicitar dicho a través de la línea telefónica de QNT. 5) Autorizo a QNT a bloquear limitar, cancelar y/o suspender mi tarjeta de crédito de forma unilateral. 6) Declaro haber comprendido y aceptado los términos y condiciones de manejo y uso de la tarjeta de crédito que me han sido proporcionados.
        </p>
      </div>
      <div>
        <p class="mt-5 pt-5">
          <u>{{identification_number}}</u>
          <br />
          No. Documento
        </p>
        <p>Fecha: {{dateFormatted}}</p>
      </div>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        block
        color="secondary"
        @click.stop.prevent="$emit('accept')"
      >
        Acepto términos legales
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>

export default {
  name: "DocumentCreditCard",
  props: {
    identification_number: {
      required: true,
      type: String
    },
    date: {
      default: () => (new Date()),
      type: Date
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
  },
  computed: {
    dateFormatted () {
      return this.formatDate(this.date.toLocaleString().substr(0, 10))
    }
  }

}
</script>
