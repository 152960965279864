<template>
  <div>
    <!-- <AppSubHeader
      link='solicitud-tdc'
      title='Mi Tarjeta de Crédito'
    /> -->
    <v-main class="white grey--text text--lighten-2">
      <PhysicalCard v-if="creditCardTDR === false && !tdrPaid" :identification_number="generalInformation.username"
        :external_id="creditCard.external_id" :quota="creditCard.quota.toLocaleString()" />
      <v-row v-if="activeRequest && creditCardTDR" fluid class="fill-height" align="center" justify="center">
        <v-container v-if="creditCardTDR === true && paidTDR === false">
          <v-row justify="center">
            <v-col cols="12" sm="10" md="8" lg="6">
              <v-card elevation="2" class="text-center pa-3" color="blue lighten-5" shaped>
                <v-card-text>
                  <div class="d-flex align-center justify-center mb-2">
                    <v-icon size="36" color="blue darken-2" class="mr-2">
                      mdi-credit-card-outline
                    </v-icon>
                    <span class="text-h6 font-weight-bold text--darken-2">
                      ¿Prefieres una tarjeta física?
                    </span>
                  </div>

                  <!-- <div class="text-body-2 mb-2">
                    Puedes cambiarla ahora.
                  </div> -->
                </v-card-text>

                <v-btn color="blue darken-2" dark rounded elevation="1" @click="showChangeCardDialog"
                  class="px-4 text-body-2">
                  <v-icon left small>mdi-swap-horizontal</v-icon>
                  Cambiar a tarjeta física
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogChangeCardType" max-width="500px">
            <v-card shaped>
              <v-card-title class="headline pa-4 blue lighten-4">
                <v-icon color="blue darken-2" size="36" class="mr-3">mdi-credit-card-outline</v-icon>
                <span class="text-h5 blue--text text--darken-2">¿Cambiar a Tarjeta Física?</span>
              </v-card-title>

              <v-card-text class="pa-6">
                <p class="text-body-1 mb-4">
                  Una tarjeta física puede ofrecerte mayor comodidad en tus transacciones diarias.
                  Realiza pagos de forma fácil y segura en cualquier establecimiento.
                </p>
                <p class="text-subtitle-1 font-weight-medium blue--text text--darken-1">
                  ¿Te gustaría cambiar a una tarjeta física?
                </p>
              </v-card-text>

              <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogChangeCardType = false" class="text-capitalize">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" outlined @click="changeToPhysicalCard" class="text-capitalize">
                  Cambiar a física
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
        <v-col cols="12" md="8" lg="6">
          <v-stepper v-model="step" vertical>
            <v-stepper-step :complete="step > 1" step="1">
              Inicio
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-form lazy-validation v-model="isValid" class="align-self-center" ref="form-1">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col>
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Vamos a solicitar tu Tarjeta de Crédito QNT en menos de 10 minutos
                    </div>
                    <div class="text-h6 grey--text text--darken-1 text-center mb-5">
                      Número de cedula<br>
                      <small>{{ generalInformation.username }}</small>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" @click="dialogMPD = true" style="cursor: pointer;" class="mx-auto">
                    <v-checkbox readonly v-model="dataForm.management_personal_data" name="tyc" ref="tyc"
                      :rules="[rules.required]" required light color="secondary">
                      <template v-slot:label>
                        <div>
                          Acepto
                          <span class="text-decoration-underline">
                            tratamiento de datos personales
                          </span>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" @click="dialogDS = true" style="cursor: pointer;" class="mx-auto">
                    <v-checkbox readonly v-model="dataForm.digital_sign" name="tyc" ref="tyc" :rules="[rules.required]"
                      required light color="secondary">
                      <template v-slot:label>
                        <div>
                          Acepto
                          <span class="text-decoration-underline">
                            firmas digitales
                          </span>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 1" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2">
              Contacto
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-2">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Cuéntanos tus datos de contacto
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-select light v-model="dataForm.residence_department" label="Departamento de residencia"
                      :items="departmentList" item-text="nombre" item-value="nombre" hide-details="auto"
                      :rules="[rules.required]"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select light v-model="dataForm.residence_city" label="Ciudad de residencia"
                      :items="residenseCityList" item-text="nombre" item-value="nombre" hide-details="auto"
                      :rules="[rules.required]"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="dataForm.address" light label="Dirección casa" hide-details="auto"
                      :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field readonly disabled v-model="dataForm.email" light label="Email" hide-details="auto"
                      :rules="rules.emailRules"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field readonly disabled v-model="dataForm.mobile" light label="Celular" hide-details="auto"
                      :rules="[rules.required]"></v-text-field>

                    <v-checkbox v-model="dataForm.is_correct_phone" light
                      label="Este es mi celular y correo eléctronico de contacto"
                      :rules="[rules.required]"></v-checkbox>
                  </v-col>

                  <v-col cols="12" v-if="!dataForm.is_correct_phone">
                    <p><b>Si el número celular o correo eléctronico no corresponde, contáctate con nuestros asesores
                        para actualizar
                        tus datos personales y continuar con el proceso.</b>
                    </p>
                  </v-col>

                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading || !dataForm.is_correct_phone"
                      :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 2" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 3" step="3">
              Cédula
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-3">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Ingresa los datos de tu cédula
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="dataForm.expedition_country" light label="¿En que país se expidió tu cédula?"
                      :items="countryList" item-text="name" item-value="name" hide-details="auto"></v-select>
                  </v-col>

                  <template v-if="dataForm.expedition_country == 'Colombia'">
                    <v-col cols="12">
                      <v-select light v-model="dataForm.expedition_department" label="¿En que departamento?"
                        :items="departmentList" item-text="nombre" item-value="nombre" hide-details="auto"
                        :rules="[rules.required]"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select light v-model="dataForm.expedition_city" label="¿En que ciudad?"
                        :items="expeditionCityList" item-text="nombre" item-value="nombre" hide-details="auto"
                        :rules="[rules.required]"></v-select>
                    </v-col>
                  </template>
                  <v-col cols="12">
                    <v-dialog v-model="dialogIssueDate" ref="dialogIssueDate"
                      :return-value.sync="dataForm.dialogIssueDate" persistent width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field light label="¿En que fecha se expidió tu cedula?"
                          v-model="dataForm.dialogIssueDate" ref="expedition_date" append-icon="mdi-calendar"
                          :rules="[rules.required]" required readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="secondary" v-model="dataForm.expedition_date" locale="es-co"
                        @input="$refs.dialogIssueDate.save(dataForm.expedition_date)" :max="current_day"
                        min="1950-01-01" :active-picker.sync="activePicker">
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 3" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 4" step="4">Datos personales
            </v-stepper-step>
            <v-stepper-content step="4">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-4">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Ahora queremos conocerte
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-dialog v-model="dialogBirthDate" ref="dialogBirthDate"
                      :return-value.sync="dataForm.dialogBirthDate" persistent width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field light label="¿En que fecha naciste?" v-model="dataForm.dialogBirthDate"
                          ref="birth_date" append-icon="mdi-calendar" :rules="[rules.required]" required readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="secondary" v-model="dataForm.birth_date" locale="es-co"
                        @input="$refs.dialogBirthDate.save(dataForm.birth_date)" :max="current_day" min="1950-01-01"
                        :active-picker.sync="activePicker">
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12">
                    <v-select v-model="dataForm.birth_country" light label="¿Cuál es tu país de nacimiento?"
                      :items="countryList" item-text="name" item-value="name" hide-details="auto"></v-select>
                  </v-col>

                  <template v-if="dataForm.birth_country == 'Colombia'">
                    <v-col cols="12">
                      <v-select light v-model="dataForm.birth_department" label="¿En qué departamento?"
                        :items="departmentList" item-text="nombre" item-value="nombre" hide-details="auto"
                        :rules="[rules.required]"></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-select light v-model="dataForm.birth_city" label="¿En qué ciudad o municipio?"
                        :items="birthCityList" item-text="nombre" item-value="nombre" hide-details="auto"
                        :rules="[rules.required]"></v-select>
                    </v-col>
                  </template>
                  <v-col cols="12">
                    <v-radio-group v-model="dataForm.gender" mandatory row :rules="[rules.required]">
                      <template v-slot:label>
                        <div>¿Cuál es tu género?</div>
                      </template>
                      <v-radio v-for="(value, key) in sexList" :key="key" light :value="value" :label="value"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 4" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 5" step="5">
              Actividad economica
            </v-stepper-step>
            <v-stepper-content step="5">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-5">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Cuéntanos a que te dedicas
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-radio-group v-model="dataForm.activity" :rules="[rules.required]" mandatory>
                      <v-radio v-for="(item, i) in economicActivityList" :value="item.value" :key="i">
                        <template v-slot:label>
                          <v-row class="mx-2" align="center">
                            <v-icon v-text="item.icon"></v-icon>
                            <div class="mx-2">{{ item.text }}</div>
                          </v-row>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 5" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 6" step="6">
              Información laboral
            </v-stepper-step>
            <v-stepper-content step="6">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-6">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Cuéntanos mas sobre tu actividad económica
                    </div>
                  </v-col>
                  <!--Asalariado-->
                  <template v-if="currentData.activity == 'Asalariado'">
                    <v-col cols="12">
                      <v-text-field v-model="dataForm.company_name" light label="Nombre de la empresa"
                        hide-details="auto" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="dataForm.company_position" light label="Cargo" hide-details="auto"
                        :rules="[rules.required]"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="dataForm.company_address" light label="Dirección" hide-details="auto"
                        :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="dataForm.company_phone" light label="Teléfono" hide-details="auto"
                        :rules="rules.phoneRules"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-select v-model="dataForm.company_department" light label="Departamento" :items="departmentList"
                        item-text="nombre" item-value="nombre" hide-details="auto" :rules="[rules.required]"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="dataForm.company_city" light label="Ciudad" :items="companyCityList"
                        item-text="nombre" item-value="nombre" hide-details="auto" :rules="[rules.required]"></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-dialog v-model="dialogEntryDate" ref="dialogEntryDate"
                        :return-value.sync="dataForm.dialogEntryDate" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field light label="Fecha de ingreso" v-model="dataForm.dialogEntryDate"
                            ref="entryDate" append-icon="mdi-calendar" :rules="rules.dateRules" required v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="secondary" v-model="dataForm.company_entry_at" locale="es-co"
                          @input="$refs.dialogEntryDate.save(dataForm.company_entry_at)" :max="current_day"
                          min="1950-01-01" :active-picker.sync="activePicker">
                        </v-date-picker>
                      </v-dialog>
                    </v-col>

                  </template>

                  <!--Independiente-->
                  <template v-else-if="currentData.activity == 'Independiente'">
                    <v-col cols="12">
                      <v-select v-model="dataForm.description_ciiu" :items="ciiuList" item-text="description"
                        item-value="description" light label="Descripción de la actividad" hide-details="auto"
                        :rules="[rules.required]"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="dataForm.independent_time" light label="Tiempo en la actividad (meses)"
                        type="number" hide-details="auto" min="1" :rules="rules.monthRules"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="dataForm.company_address" light label="Dirección" hide-details="auto"
                        :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="dataForm.company_phone" light label="Teléfono" hide-details="auto"
                        :rules="rules.phoneRules"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="dataForm.company_department" light label="Departamento" :items="departmentList"
                        item-text="nombre" item-value="nombre" hide-details="auto" :rules="[rules.required]"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="dataForm.company_city" light label="Ciudad" :items="companyCityList"
                        item-text="nombre" item-value="nombre" hide-details="auto" :rules="[rules.required]"></v-select>
                    </v-col>
                  </template>
                  <!--Pensionado-->
                  <template v-else-if="currentData.activity == 'Pensionado'">
                    <v-col cols="12">
                      <v-dialog v-model="dialogRetiredDate" ref="dialogRetiredDate"
                        :return-value.sync="dataForm.dialogRetiredDate" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field light label="Desde cuando está pensionado" v-model="dataForm.dialogRetiredDate"
                            ref="retiredDate" append-icon="mdi-calendar" :rules="[rules.required]" required
                            v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="secondary" v-model="dataForm.retired_at" locale="es-co"
                          @input="$refs.dialogRetiredDate.save(dataForm.retired_at)" :max="current_day" min="1950-01-01"
                          :active-picker.sync="activePicker">
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="dataForm.pension_fund_entity" light
                        label="¿Que entidad le realiza el pago?" hide-details="auto"
                        :rules="[rules.required]"></v-text-field>
                    </v-col>
                  </template>
                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 6" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 7" step="7">
              Información financiera
            </v-stepper-step>
            <v-stepper-content step="7">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-7">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Cuéntanos acerca de tus finanzas
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <vuetify-money v-model="dataForm.monthly_income" light label="¿Cuál es tu ingreso mensual?"
                      hide-details="auto" :rules="[rules.required, rules.minMoney0]" :options="currencyOptions" />
                  </v-col>

                  <v-col cols="12">
                    <vuetify-money v-model="dataForm.monthly_expense" light label="¿Cuanto suman tus gastos mensuales?"
                      hide-details="auto" :rules="[rules.required, rules.minMoney0]" :options="currencyOptions" />
                  </v-col>

                  <v-col cols="12">
                    <vuetify-money v-model="dataForm.personal_property" light label="¿A cuanto ascienden tus activos?"
                      hide-details="auto" :rules="[rules.required, rules.minMoney0]" :options="currencyOptions" />
                  </v-col>

                  <v-col cols="12">
                    <vuetify-money v-model="dataForm.personal_debts" light label="¿Cual es el total de tus pasivos?"
                      hide-details="auto" :rules="[rules.required, rules.minMoney0]" :options="currencyOptions" />
                  </v-col>

                  <v-col cols="12">
                    <v-radio-group v-model="dataForm.foreing_taxes" mandatory row>
                      <template v-slot:label>
                        <div>¿Pagas impuestos en otros países?</div>
                      </template>
                      <v-radio v-for="(item, key) in binaryList" :key="key" light :value="item.value"
                        :label="item.label"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12">
                    <v-radio-group v-model="dataForm.foreing_currency_operations" mandatory row>
                      <template v-slot:label>
                        <div>¿Realizas operaciones en moneda extranjera?</div>
                      </template>
                      <v-radio v-for="(item, key) in binaryList" :key="key" light :value="item.value"
                        :label="item.label"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12">
                    <v-radio-group v-model="dataForm.public_resources" mandatory row>
                      <template v-slot:label>
                        <div>¿Manejas recursos públicos, eres una persona públicamente expuesta o un familiar allegado
                          lo es?</div>
                      </template>
                      <v-radio v-for="(item, key) in binaryList" :key="key" light :value="item.value"
                        :label="item.label"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 7" :errors="errors" />
            </v-stepper-content>
            <v-stepper-step :complete="step > 8" step="8">
              Referencias
            </v-stepper-step>
            <v-stepper-content step="8">
              <v-form lazy-validation :value="isValid" class="align-self-center mb-5" ref="form-8">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h6 grey--text text--darken-1 text-center mb-5">
                      Referencia familiar
                    </div>
                    <v-text-field v-model="dataForm.reference1_name" light label="Nombre y apellidos"
                      hide-details="auto" :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select v-model="dataForm.reference1_department" light label="Departamento"
                      :items="departmentList" item-text="nombre" item-value="nombre" hide-details="auto"
                      :rules="[rules.required]"></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-select v-model="dataForm.reference1_city" light label="Ciudad" :items="reference1CityList"
                      item-text="nombre" item-value="nombre" hide-details="auto" :rules="[rules.required]"></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field v-model="dataForm.reference1_phone" light label="Teléfono de contacto"
                      hide-details="auto" :rules="rules.phoneRules"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <div class="text-h6 grey--text text--darken-1 text-center mb-5">
                      Referencia personal
                    </div>
                    <v-text-field v-model="dataForm.reference2_name" light label="Nombre y apellidos"
                      hide-details="auto" :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select v-model="dataForm.reference2_department" light label="Departamento"
                      :items="departmentList" item-text="nombre" item-value="nombre" hide-details="auto"
                      :rules="[rules.required]"></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-select v-model="dataForm.reference2_city" light label="Ciudad" :items="reference2CityList"
                      item-text="nombre" item-value="nombre" hide-details="auto" :rules="[rules.required]"></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field v-model="dataForm.reference2_phone" light label="Teléfono de contacto"
                      hide-details="auto" :rules="rules.phoneRules"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 8" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 9" step="9">
              Soporte
            </v-stepper-step>
            <v-stepper-content step="9">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-9">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      ¿A donde enviamos tu nueva Tarjeta de Crédito QNT?
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-radio-group v-model="dataForm.addrees_sent_at" :rules="[rules.required]">
                      <template v-slot:label>
                        <div>¿A donde enviamos tu nueva Tarjeta de Crédito QNT?</div>
                      </template>
                      <v-radio v-if="currentData.address" light :value="residenceAddres">
                        <template v-slot:label>
                          <div>Dirección de domicilio <br /><small> {{ residenceAddres }}</small></div>
                        </template>
                      </v-radio>

                      <v-radio v-if="currentData.company_address" light :value="companyAddres">
                        <template v-slot:label>
                          <div>Dirección laboral <br /><small>{{ companyAddres }}</small></div>
                        </template>
                      </v-radio>

                    </v-radio-group>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input v-model="dataForm.file_document" accept="image/*,.pdf" label="Adjunta tu cedula" light
                      prepend-icon="mdi-paperclip"
                      hint="Debes adjuntar una imagen legible de tu cedula por ambos lados, en formato PDF"
                      persistent-hint :rules="rules.fileRules"></v-file-input>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input v-model="dataForm.file_support" accept="image/*,.pdf"
                      label="Adjunta soporte de tus ingresos" light prepend-icon="mdi-paperclip" :hint="hint_support"
                      persistent-hint :rules="rules.fileRules"></v-file-input>
                  </v-col>

                  <v-col cols="12" @click="dialogAcceptedLetter = true" style="cursor: pointer;" class="mx-auto">
                    <v-checkbox readonly v-model="dataForm.accepted_letter" name="accepted_letter" ref="accepted_letter"
                      :rules="[rules.required]" required light color="secondary">
                      <template v-slot:label>
                        <div>
                          Acepto
                          <span class="text-decoration-underline">
                            carta de Condiciones
                          </span>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" @click="dialogAccptedCC = true" style="cursor: pointer;" class="mx-auto">
                    <v-checkbox readonly v-model="dataForm.accepted_cc" name="accepted_cc" ref="accepted_cc"
                      :rules="[rules.required]" required light color="secondary">
                      <template v-slot:label>
                        <div>
                          Acepto
                          <span class="text-decoration-underline">
                            contrato producto tarjeta de crédito
                          </span>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>

                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 9" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 10" step="10">
              OTP
            </v-stepper-step>
            <v-stepper-content step="10">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-10">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      Ingresa el código de confirmación enviado a tu celular y correo électrónico.
                    </div>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field v-model="dataForm.otp1" light hide-details="auto" maxlength=1 :rules="[
        rules.required,
        v => /^[0-9]{1}?$/.test(v) || 'Código invalido']"></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field v-model="dataForm.otp2" light hide-details="auto" maxlength=1 :rules="[
        rules.required,
        v => /^[0-9]{1}?$/.test(v) || 'Código invalido']"></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field v-model="dataForm.otp3" light hide-details="auto" maxlength=1 :rules="[
        rules.required,
        v => /^[0-9]{1}?$/.test(v) || 'Código invalido']"></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field v-model="dataForm.otp4" light hide-details="auto" maxlength=1 :rules="[
        rules.required,
        v => /^[0-9]{1}?$/.test(v) || 'Código invalido']"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <p class="text-small text-center">Ingresa el código de confirmación enviado a tu celular y correo
                      electrónico,
                      si no lo recibiste, por favor comunícate a la línea (+601)9183131.</p>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      Validar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-btn color="secondary" @click.stop.prevent="generateOtp" :disabled="loading" :loading="loading">
                      Enviar nuevamente el código
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <ErrorFormAlert v-if="step === 10" :errors="errors" />
              <ErrorFormAlert v-if="step === 10" :errors="requestMsg" type="warning" />
            </v-stepper-content>

            <v-stepper-step v-if="is_digital" :complete="step > 11" step="11">
              Activación
            </v-stepper-step>
            <v-stepper-content step="11">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-11">
                <v-row class="fill-height" align="center" justify="center" v-if="is_digital">
                  <v-container fluid>
                    <v-checkbox v-model="autoActivation"
                      light color="secondary">
                      <template v-slot:label>
                        <div>
                          Autorizo la activación de mi Tarjeta de Rebancarización Digital en 5 días hábiles posteriores a la firma del pagaré.
                        </div>
                      </template>
                    </v-checkbox>
                  </v-container>
                  <v-col cols="12">
                    <v-btn color="primary" @click.stop.prevent="next" :disabled="loading" :loading="loading">
                      CONTINUEMOS
                    </v-btn>
                  </v-col>
                </v-row>

              </v-form>
              <ErrorFormAlert v-if="step === 11 && is_digital" :errors="errors" />
            </v-stepper-content>

            <v-stepper-step :step="is_digital ? 12 : 11">
                  Finalización
              </v-stepper-step>
            <v-stepper-content :step="is_digital ? 12 : 11">
              <v-form lazy-validation :value="isValid" class="align-self-center" ref="form-12">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="12">
                    <div class="text-h5 grey--text text--darken-1 text-center mb-5">
                      ¡Felicidades! Tu solicitud de Tarjeta de Crédito QNT fue exitosa.
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="text-center mb-5">
                      <p>Ahora recibirás un correo electrónico de "administrador@pagares.bvc.com.co" para realizar la
                        firma de
                        pagare y carta de instrucciones y culminar tu proceso.</p>
                      <p>Para consultar los documentos que acabas de firmar, los podras consultar en el siguiente link
                        <router-link :to="{ name: 'user-agreements', params: { reload: true } }">Mis
                          contratos</router-link> o ingresando a
                        <router-link :to="{ name: 'index', params: { reload: true } }">MI QNT</router-link>.
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
          </v-stepper>

          <v-row justify="center">
            <v-dialog v-model="dialogTyC" width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Términos legales QNT</span>
                </v-card-title>
                <v-card-text>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn block color="secondary" @click="acceptingTyC">
                    Acepto términos legales
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogMPD" width="600px">
              <DocumentPersonalData @accept="acceptingMPD" :identification_number="generalInformation.username" />
            </v-dialog>

            <v-dialog v-model="dialogDS" width="600px">
              <DocumentDigitalSign @accept="acceptingDS" :identification_number="generalInformation.username" />
            </v-dialog>

            <v-dialog v-model="dialogAcceptedLetter" width="600px">
              <DocumentAcceptedProduct @accept="acceptingLetter" :identification_number="generalInformation.username"
                :quota="creditCard.quota.toLocaleString()" :name="name" />
            </v-dialog>

            <v-dialog v-model="dialogAccptedCC" width="600px">
              <DocumentCreditCard @accept="acceptingCreditCard" :identification_number="generalInformation.username" />
            </v-dialog>

            <v-dialog v-model="dialogAutoActivation" persistent width="500px">
              <v-card>
                <v-card-title class="headline pa-4 blue lighten-4">
                  <v-icon color="blue darken-2" size="30" class="mr-3">mdi-credit-card-outline</v-icon>
                </v-card-title>

              <v-card-text class="pa-6">
                <p class="text-body-1 font-weight-regular">
                  ¿Deseas deshabilitar la activación automática de tu Tarjeta Digital? <br> Para activarla, deberás contactarnos a través de nuestra línea de servicio al cliente <strong>6019183131 Opc. 2 Opc.2</strong>.
                </p>
                <!-- <p class="text-body-1 font-weight-regular">
                  Te enviaremos un correo electronico una vez hallamos creado tu tarjeta, invitandote a ponerte en contacto para completar la activación.
                </p> -->
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="cancelDesactivateAutoActivation" class="text-capitalize">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" outlined @click="acceptDesactivateAutoActivation" class="text-capitalize">
                  Aceptar
                </v-btn>
              </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else-if="acceptedCreditCard">
        <v-col>
          <v-card>
            <v-card-text>
              <div class="text-center text-h5">La solicitud de su tarjeta de crédito ha sido aceptada</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar
          v-model="errorDialog"
          color="error"
          top
          multi-line
          timeout="5000"
        >
        {{errorMessage}}
    </v-snackbar>
    <v-snackbar
          v-model="successDialog"
          color="success"
          top
          multi-line
          timeout="5000"
        >
        {{successMessage}}
    </v-snackbar>
    </v-main>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import AppSubHeader from "@/components/layout/AppSubHeader.vue"
import DocumentPersonalData from "@/components/document/DocumentPersonalData"
import DocumentDigitalSign from "@/components/document/DocumentDigitalSign"
import DocumentCreditCard from "@/components/document/DocumentCreditCard"
import DocumentAcceptedProduct from "@/components/document/DocumentAcceptedProduct"
import ErrorFormAlert from "@/components/ui/ErrorFormAlert"
import parameterService from '@/services/parameters.service'
import PhysicalCard from '@/views/user/PhysicalCard.vue'
import productsService from "../../services/products.service"
import axios from 'axios'
import { HTTP } from "../../services/http-common"


export default {
  name: "CreditCardRequestPage",
  components: {
    AppSubHeader, DocumentDigitalSign, DocumentPersonalData, ErrorFormAlert,
    DocumentCreditCard, DocumentAcceptedProduct, PhysicalCard
  },
  data() {
    return {
      isValid: false,
      dialogMPD: false,
      dialogDS: false,
      dialogAcceptedLetter: false,
      dialogAccptedCC: false,
      expedition_date: null,
      dialogEntryDate: null,
      activePicker: null,
      dialogIssueDate: false,
      dialogBirthDate: false,
      dialogRetiredDate: false,
      dialogChangeCardType: false,
      dialogAutoActivation: false,
      dataStep: {},
      residenseCityList: [],
      expeditionCityList: [],
      birthCityList: [],
      companyCityList: [],
      reference1CityList: [],
      reference2CityList: [],
      dataForm: { expedition_country: 'Colombia', birth_country: 'Colombia' },
      dialogTyC: false,
      valid: true,
      errors: null,
      errorDialog: false,
      errorMessage: null,
      autoActivation: true,
      dialogAutoActivation: false,
      rules: {
        required: value => !!value || 'Este campo es requerido',
        minMoney0: value => value.replaceAll('.', '') > 0 || 'Este campo debe ser superior a $0',
        min5: v => (!!v && v.length >= 5) || 'Dato invalido',
        numDoc: v => (!!v && /^[1-9]{1}[0-9]{4}/.test(v)) || 'Ingrese números validos',
        emailRules: [
          v => !!v || 'Correo es requerido',
          v => /.+@.+\..+/.test(v) || 'Correo debe ser valido',
        ],
        phoneRules: [
          v => !!v || 'Teléfono es requerido',
          v => /^[1-9]\d{6,9}$/.test(v) || 'Número debe ser valido',
        ],
        mobileRules: [
          v => !!v || 'Celuar es requerido',
          v => /^3[0-5]{1}\d{8}$/.test(v) || 'Número de celular debe ser valido',
        ],
        dateRules: [
          v => !!v || 'Fecha es requerida',
          v => /^[1-2]{1}\d{3}-[0-1][0-9]-[0-3][0-9]$/.test(v) || 'Fecha invalida',
        ],
        monthRules: [
          v => !!v || 'Tiempo en meses es requerido',
          v => /^[1-9]{1}([0-9]{1,2})?$/.test(v) || 'Tiempo en meses no es valido',
        ],
        fileRules: [
          v => !!v || 'El archivo es requerido',
          value => (!!value && value.size > 0) || 'El archivo no puede estat vacio',
          value => (!!value && value.size < 10000000) || 'El archivo debe ser de menos de 10 MB'
        ]
      },
      currencyOptions: {
        locale: "es-CO",
        prefix: "$",
        suffix: "",
        length: 11,
        precision: 0
      },
    }
  },
  props: {
    is_digital: Boolean
  },
  async created() {
    await this.getProcessCreditCard()
    if ((!this.step || this.step <= 2) && !this.preferredLocation.mobile) {
      await this.$store.dispatch("products/getPreferredLocation")
    }
    if (this.plans.length < 1) {
      this.$store.dispatch("plans/list")
    }
    if (this.feeList.length < 1) {
      this.$store.dispatch("fees/getAllFees");
    }
    if (!this.departmentList.length && (!this.step || this.step <= 8)) {
      this.$store.dispatch("parameters/getStates")
    }
    if (!this.countryList.length && (!this.step || this.step <= 4)) {
      this.$store.dispatch("parameters/getAllCountries")
    }
    if ((!this.step || this.step <= 6) && this.currentData.activity === 'Independiente' && !this.ciiuList.length) {
      this.$store.dispatch("parameters/geCIIUList")
    }
    Object.assign(this.dataForm, this.preferredLocation)

    const ref_payco = this.$route.query.ref_payco;
    if (ref_payco) {
      try {
        const response = await axios.get(`https://secure.epayco.co/validation/v1/reference/${ref_payco}`);
        const transactionData = response.data.data;
        
        const transactionState = transactionData.x_cod_transaction_state;

        const confirmation = await this.getPaymentConfirmation(transactionData)
        
        if(confirmation !== null) {
          const transactionState = confirmation.message

          if (transactionState === "Transacción aceptada") {
          console.log('Transaction accepted')
          localStorage.removeItem("invoice")
          this.confirmTransaction()
          this.getQntProducts();
          this.$router.push({ name: 'tc-request' });

        } else if (transactionState === "Transacción pendiente") {
          console.log('Transaction pending')
          // this.loading4 = true;
          // this.showPayment = false;
          setTimeout(() => {
            location.reload();
          }, 20000);

        } else {
          // this.loading4 = true;
          // this.showPayment = false;
          setTimeout(() => {
            this.$router.push('/');
          }, 10000);
        }
        }
        // if (this.transactionState == '1') {
        //   this.loading4 = true;
        //   console.log('Transaction accepted')
        //   this.creditCardTDR = true

        // } else if (this.transactionState == '3') {
        //   console.log('Transaction pending')
        //   // this.loading4 = true;
        //   // this.showPayment = false;
        //   setTimeout(() => {
        //     location.reload();
        //   }, 20000);

        // } else {
        //   // this.loading4 = true;
        //   // this.showPayment = false;
        //   console.log('Transaction rejected')
        //   setTimeout(() => {
        //     this.$router.push('/');
        //   }, 10000);
        //   this.loading4 = false;
        // }
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      }
    }
    
  },
  async mounted() {
    if (this.preferredLocation.mobile && this.step <= 2) {
      Object.assign(this.dataForm, this.preferredLocation)
    }
  },
  methods: {
    async next() {
      try {
        if (this.$refs["form-" + this.step].validate()) {
          this.dataStep = { ...this.dataForm }
          this.dataStep['step'] = this.step
          this.dataStep['external_id'] = this.creditCard.external_id
          if (this.step === 1) {
            this.dataStep["is_digital"] = this.is_digital
          }
          if (this.step === 6) {
            this.dataStep['activity'] = this.currentData.activity
          }
          if(this.step === 11) {
            this.dataStep['authorizeActivation'] = this.autoActivation
            this.dataStep["is_digital"] = this.is_digital
          }
          // Get activity
          if ((this.currentData.activity === 'Independiente' || this.dataForm.activity === 'Independiente') &&
            (this.step >= 5 || this.step <= 6) &&
            !this.ciiuList.length) {
            this.$store.dispatch("parameters/geCIIUList")
          }
          await this.requestProcessCreditCard(this.dataStep)
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status == 401) {
            this.$store.dispatch("users/logout");
            window.location = '/signin';
          } else {
            if (error.response.data.errors){
              this.errors = error.response.data.errors
            }
            else {
              this.errors = error.response.data
            }
          }
        } else {
          throw error
        }
      }
    },
    generateOtp() {
      try {
        const data = { external_id: this.creditCard.external_id }
        this.generateOTPCC(data)
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data
        } else {
          throw error
        }
      }
    },
    showChangeCardDialog() {
      this.dialogChangeCardType = true;
    },
    async changeToPhysicalCard() {
      this.dialogChangeCardType = false;
      const id = this.creditCard.external_id;
      try {
        await productsService.requestUpdateisDigitalValue(id, { is_digital: false, step: this.step - 1 });
      } catch (error) {
        if (error.response && error.response.status !== 404) {
          // Verifica si el error es de tipo "stepNotAllowed"
          if (error.response.data.errors && error.response.data.errors.stepNotAllowed) {
            this.errorDialog = true;
            this.errorMessage = 'No es posible cambiar a tajerta física en este paso de la solicitud.';
          } else {
            // Otros errores
            this.errorDialog = true;
            this.errorMessage = 'Ocurrió un problema y no se pudo hacer el cambio de solicitud a tarjeta física';
          }
          return;
        }
        return;
      }
      this.$router.push({ name: 'tc-request', query: { is_digital: false } });
      this.showPaymentComponent = true;
    },
    handlePaymentSuccess() {
      this.showPaymentComponent = false;
    },
    acceptingTyC() {
      this.dataForm.term_conditions = true
      this.dialogTyC = false
    },
    acceptingMPD() {
      this.dataForm.management_personal_data = true
      this.dialogMPD = false
    },
    acceptingDS() {
      this.dataForm.digital_sign = true
      this.dialogDS = false
    },
    acceptingLetter() {
      this.dataForm.accepted_letter = true
      this.dialogAcceptedLetter = false
    },
    acceptingCreditCard() {
      this.dataForm.accepted_cc = true
      this.dialogAccptedCC = false
    },
    cancelDesactivateAutoActivation(){
      this.dialogAutoActivation = false
      this.autoActivation = true
    },
    async acceptDesactivateAutoActivation() {
      this.dialogAutoActivation = false
      this.autoActivation = false
      // setTimeout(()=> {
      //   window.location = "/"
      // }, 4000)
    },
    ...mapActions('products', [
      'requestProcessCreditCard', 'getProcessCreditCard', 'generateOTPCC', 'getQntProducts']),
    ...mapActions('epayco', ['confirmTransaction']),
    async reject() {
      if (this.$refs.form.validate()) {
        try {
          await this.rejectCreditCard({ ...this.creditCard, rejectedReason: this.comment });
          this.getQntProducts();
          this.$router.push({ name: 'index' });
        } catch (error) {
          this.errors = error;
        }
      }
      return 1
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      if (!month) return null
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async getCityList(state) {
      if (state) {
        const res = await parameterService.getCitiesByNameStateList(state)
        return res.data
      }
      return []
    },
    async getPaymentConfirmation(data) {
      try{
        await HTTP.post("/miQnt/Pay/confirmation/", data)
      }
      catch(error) {
        console.log(error.response);
        
        if(error.response && error.response.status === 404){
          console.log("invoice not found");
          
        }
      }
      const invoice_id = localStorage.getItem("invoice")
      if(invoice_id) {
        const numeroIdentificacion = this.generalInformation.username
        const external_id = this.creditCard.external_id
        const quota = this.creditCard.quota
        try{
          const checkResponse = await HTTP.get(`/miQnt/pay/check-payment-status/${invoice_id}/${numeroIdentificacion}/${external_id}/${quota}`);
          return checkResponse.data
        }
        catch(error) {
          console.log("error payment status" + " " + error.response.data);
          
          return null
        }
      }
      return null
    }
  },

  computed: {
    ...mapState({
      generalInformation: state => state.users.generalInformation,
      preferredLocation: state => state.products.preferredLocation,
      cityList: state => state.parameters.allCities,
      departmentList: state => state.parameters.states,
      countryList: state => state.parameters.allCountries,
      binaryList: state => state.parameters.binaryList,
      economicActivityList: state => state.parameters.economicActivityList,
      sexList: state => state.parameters.sexList,
      ciiuList: state => state.parameters.ciiuList,
      currentData: state => state.products.requestCreditCard,
      step: state => state.products.stepRequestCreditCard,
      loading: state => state.products.loading,
      feeList: state => state.fees.all,
      plans: state => state.plans.all,
      requestMsg: state => state.products.requestMsg,
      tdrPaid: state => state.epayco.tdrPaid
    }),
    ...mapGetters({
      creditCard: 'products/activeCreditCard',
      acceptedCreditCard: 'products/acceptedCreditCard',
      name: 'users/name',
      hasSpecialDebt: 'products/hasSpecialDebt',
      haveDelinquentAccount: 'plans/haveDelinquentAccounts',
      hasPendingFees: 'fees/hasPendingFees',
    }),
    current_day() {
      let date = new Date();
      date.setHours(0)
      return date.toISOString().substr(0, 10)
    },
    haveDelinquentAccounts() {
      return this.haveDelinquentAccount || this.hasPendingFees
    },
    activeRequest() {
      return this.creditCard && !(this.haveDelinquentAccounts || this.creditCard.delinquentReason) && this.step > 0
    },
    hint_support() {
      if (this.currentData.activity === 'Asalariado') {
        return 'Desprendible de pago de nómina último mes o certificación laboral no mayor a 60 días'
      }
      else if (this.currentData.activity === 'Independiente') {
        return 'Extractos bancarios de últimos 3 meses, certificaciones comerciales, certificaciones de proveedores o tarjeta profesional'
      }
      if (this.currentData.activity === 'Pensionado') {
        return 'Comprobante de pago de pensión último mes'
      }
    },
    companyAddres() {
      return `${this.currentData.company_address} ${this.currentData.company_department}, ${this.currentData.company_city}`
    },
    residenceAddres() {
      return `${this.currentData.address} ${this.currentData.residence_department}, ${this.currentData.residence_city}`
    },
    otp() {
      return `${this.dataForm.otp1}${this.dataForm.otp2}${this.dataForm.otp3}${this.dataForm.otp4}`
    },
    paidTDR() {
      let isDigital;

      if (this.creditCard.user_credit_card_application && this.creditCard.user_credit_card_application.hasOwnProperty('is_digital')) {
        isDigital = this.creditCard.user_credit_card_application.is_digital;
      } else {
        isDigital = this.is_digital;
      }

      if (isDigital === false && this.creditCard.user_credit_card_application.is_pay === true) {
        return true;
      } else {
        return false;
      }
    },
    creditCardTDR() {
      if (this.is_digital === true || (this.creditCard && this.creditCard.user_credit_card_application && this.creditCard.user_credit_card_application.is_digital === true)) {
        return true;
      } else if (this.creditCard && this.creditCard.user_credit_card_application && this.creditCard.user_credit_card_application.is_digital === false && this.creditCard.user_credit_card_application.is_pay) {
        return true;
      }
      return false;
    }

  },
  watch: {
    dialogIssueDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dialogBirthDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dialogRetiredDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dialogEntryDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    'preferredLocation.mobile'(val) {
      this.dataForm.mobile = val
    },
    'preferredLocation.email'(val) {
      this.dataForm.email = val
    },
    async 'dataForm.residence_department'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.residenseCityList = await this.getCityList(newVal)
      }
    },
    'dataForm.expedition_country'(newVal, oldVal) {
      if (oldVal && newVal !== 'Colombia') {
        this.$set(this.dataForm, 'expedition_department', '')
        this.$set(this.dataForm, 'expedition_city', '')
      }
    },
    async 'dataForm.expedition_department'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.expeditionCityList = await this.getCityList(newVal)
      }
    },
    'dataForm.birth_country'(newVal, oldVal) {
      if (oldVal && newVal !== 'Colombia') {
        this.$set(this.dataForm, 'birth_department', '')
        this.$set(this.dataForm, 'birth_city', '')
      }
    },
    async 'dataForm.birth_department'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.birthCityList = await this.getCityList(newVal)
      }
    },
    async 'dataForm.company_department'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.companyCityList = await this.getCityList(newVal)
      }
    },
    async 'dataForm.reference1_department'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reference1CityList = await this.getCityList(newVal)
      }
    },
    async 'dataForm.reference2_department'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reference2CityList = await this.getCityList(newVal)
      }
    },
    otp(newVal) {
      this.$set(this.dataForm, 'otp', newVal)
    },
    autoActivation(activated){
      if(!activated){
        this.dialogAutoActivation = true 
      }
    }
  }
};
</script>